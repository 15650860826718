<template>
    <div class="header">
      <div class="nav">
        <div class="title">总激活销量</div>
        <div class="share" @click="toshare()">
          <img src="~@/assets/images/distributionIndex/share.png" alt="">
          <p style="font-size:14px;">分享</p>
          </div>
      </div>
      <div class="date">2022.07.01--{{timeNow}}</div>
      <div class="unactivated">
        <p style="color:#57BAFF;margin-bottom: 3px;">未激活销量</p>
        <p style="color:#C4EDFF;">{{ info.noActive }}张</p>
      </div>
      <div class="level">
        <div class="level-text">
          <span>{{ info.Activated }}</span>
          <span class="Company">张</span>
        </div>
        <div class="progress" v-if="info.pid == 0">
          <van-progress v-if="info.leveljoin"
            :percentage="(info.leveljoin ? (info.count / info.leveljoin.number) * 100 : 0)" color="#71EBF8"
            :show-pivot='false' />
        </div>
      <div class="level-info" v-if="info.pid == 0"><span v-if="info.leveljoin ? true : false"
            style="margin-right: 10px;">L{{ info.level ? info.level : '' }}</span><span
            v-if="info.leveljoin ? true : false">{{ info.count }}/{{ info.leveljoin ? info.leveljoin.number : '' }}</span></div>
      </div>
      <div class="profit">
        <div class="profit-title">
          总收益金额
        </div>
        <div class="profit-info">
          <div class="profit-info-left">
            <span>{{ info.revenue }}</span>
            <span>元</span>
          </div>
          <!--  @click="go('Withdraw')" -->
          <div class="profit-info-right" @click="toasts()">
            <img src="~@/assets/images/distributionIndex/moneyBag.png" alt="">
            <span>去提现</span>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    props:{
        info:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        timeNow:{
            type:String,
            default:''
        }
    },
    methods:{
         //前往销卡明细
    toasts () {
      this.$toast("功能开发中,敬请期待！");
    },
    toshare(){
      this.$router.push({
          path: `/sharePage`,
        }); //类似get请求  数据会暴露在地址栏  (携带数据跳转)


    }

    }
}
</script>


<style scoped lang="less">

 .header {
    width: 100%;
    height: 310px;
    background: url('~@/assets/images/distributionIndex/bg.png');
    background-size: contain;
    padding-top: 20px;
    box-sizing: border-box;

.share {
  position: absolute;
  right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 0 !important;
  }
}
    .unactivated {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      right: 30px;
      top: 125px;
    }

    .nav {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-left: 15px;
        // margin-right: 122px;
      }

      font-size: 18px;
      color: white;

      .title {
        margin: 0 auto;
      }
    }

    .date {
      margin: 0 auto;
      width: 180px;
      height: 17px;
      font-size: 12px;
      color: white;
      margin-top: 7px;
      text-align: center;
    }

    .level {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 200px;
      height: 105px;
      margin: 0 auto;

      .level-text {
        margin-top: 20px;
        font-size: 50px;
        color: white;

        .Company {
          font-size: 14px;
          color: #fff;
        }
      }

      .level-info {
        font-size: 12px;
        color: #fff;
      }

      .progress {
        margin-top: 20px;
        margin-bottom: 10px;
        width: 121px;

        /deep/ .van-progress__portion {
          width: 120px;
        }
      }


    }

    .profit {
      position: relative;
      color: #333333;
      margin: 0 auto;
      margin-top: 20px;
      width: 345px;
      height: 80px;
      border-radius: 10px;
      background-color: #FFFFFF;
      padding: 12px 22px;
      box-sizing: border-box;
      box-shadow: 0 1px 8px 0px rgb(209, 209, 209);

      .profit-info {
        display: flex;
        height: 43px;
        align-items: center;
        justify-content: space-between;

        .profit-info-left {
          color: #117EFE;
          font-size: 30px;
          font-weight: 500;
        }

        .profit-info-left span:last-child {
          font-size: 12px;
        }

        .profit-info-right {
          display: flex;
          align-items: center;

          width: 66px;
          height: 28px;
          padding: 0 4px;
          border-radius: 4px;
          // box-sizing: border-box;
          border: 1px solid #117EFE;
          color: #117EFE;
          font-size: 14px;

          img {
            width: 17px;
            height: 17px;
          }
        }
      }
    }

  }

</style>