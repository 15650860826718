<!--  -->
<template>
  <div class="box">
    <headers
      :info="info"
      :timeNow="timeNow"
    ></headers>
    <div class="contentbox">
      <div class="rectangle">
        <div
          class="rectangle-item"
          @click="go(item.path)"
          v-for="(item, index) in iconlist"
          :key="index"
        >
          <img
            :src="item.icon"
            alt=""
          />
          <span>{{ item.name }}</span>
        </div>
      </div>

      <!-- 切换 -->
      <div class="tabs">
        <div
          class="tabs-item"
          @click="tabActiveFn(item.id)"
          v-for="item in tabsList"
          :key="item.id"
        >
          <img
            :src="item.icon"
            alt=""
          />
          <span>{{ item.name }}</span>
        </div>
      </div>
      <!-- 第一个tab  我的下级-->
      <div
        class="table-info"
        v-show="tabActive == 0"
      >
        <img
          class="upicon"
          src="~@/assets/images/distributionIndex/upicon.png"
          alt=""
        />
        <div class="table-info-header">
          <div class="nickname">昵称</div>
          <div class="creattime">加入日期</div>
          <div class="money">单张分佣</div>
          <div class="upload">上传订单</div>
          <div class="count">销卡明细</div>
          <div class="count">分配卡片</div>
        </div>

        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          offset="60"
        >
          <div
            class="table-info-item"
            v-for="(item, index) in userlist"
            :key="index"
          >
            <!-- <div class="avatar">
              <img
                src="~@/assets/images/distributionIndex/avatar.png"
                alt=""
              />
            </div> -->
            <div class="table-info-content">
              <div
                class="nickname"
                @click="edit(0, item)"
              >
                {{ item.nickname }}
              </div>
              <div class="creattime">{{ item.createtime_text }}</div>
              <div
                class="money"
                @click="edit(1, item)"
              >{{ item.money }}</div>
              <div
                class="upload"
                @click="go('uploadOeder', item)"
              >
                上传订单
              </div>
              <div
                class="count"
                @click="go('cancelCard', item)"
              >
                {{ item.count }}张
              </div>

              <div
                class="count"
                @click="go('allotPage', item,item.nickname)"
              >
                <!--                -->
                <img
                  style="width:16px;height:16px;"
                  src="~@/assets/images/distributionIndex/sendCard.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </van-list>
      </div>

      <!-- 第二个tab -->

      <tabConten
        v-show="tabActive == 1"
        ref="tabtwo"
      ></tabConten>
      <!-- 第三个tab  收益列表-->
      <div
        class="table-info"
        v-show="tabActive == 2"
      >
        <img
          class="upicon2"
          src="~@/assets/images/distributionIndex/upicon.png"
          alt=""
        />
        <div class="table-info-header2">
          <div
            class="month"
            @click="changeMonth()"
          >
            <span>{{ activemonth }}</span><span class="yue">月</span><img
              src="~@/assets/images/distributionIndex/downIcon.png"
              alt=""
            />
          </div>
          <div class="totalMoney">
            <span>总收入</span>
            <span>￥{{ moneyListInfo.allmoney }}</span>
          </div>
        </div>
        <div class="detail"><span>收入明细 ></span></div>

        <van-list
          v-model="loading2"
          :finished="finished2"
          finished-text="没有更多了"
          @load="onLoad2"
          offset="10"
        >
          <div
            class="table-info-item2"
            v-for="item in moneyListInfo.moneyList"
            :key="item.id"
          >
            <div class="avatar">
              <img
                src="~@/assets/images/distributionIndex/avatar.png"
                alt=""
              />
            </div>
            <div class="info-item2-content">
              <span class="nickname">{{ item.userjoin.nickname }}</span>
              <span class="time">{{ item.createtime_text }}</span>
              <span class="money">+￥{{ item.change }}</span>
            </div>
          </div>
        </van-list>
      </div>
      <!-- 第四个tab  设置-->
      <div
        class="table-info"
        v-show="tabActive == 3"
      >
        <img
          class="upicon3"
          src="~@/assets/images/distributionIndex/upicon2.png"
          alt=""
        />
        <!-- 调整统一额度 -->
        <div class="allocation">
          <div class="allocation-left">
            <div>
              <img
                class="tableCir"
                src="~@/assets/images/distributionIndex/tableCir.png"
                alt=""
              />
            </div>
            <div class="allocation-title">
              <p>单张卡片分佣额度</p>
              <p>该设置可统一更改所有下线分佣额度</p>
            </div>
          </div>
          <div
            class="allocation-step"
            @click="edit(3)"
          >
            <span>{{ allocation }}</span>
            <img
              style="width: 6.67px; height: 11.67px"
              class="tableCir"
              src="~@/assets/images/distributionIndex/rightIcon.png"
              alt=""
            />
            <!-- <span></span> -->
          </div>
        </div>

        <!-- 绑定银行卡 -->
        <div
          class="allocation"
          @click="toasts()"
        >
          <div class="allocation-left">
            <div>
              <img
                style="width: 24px; height: 18px"
                class="tableCir"
                src="~@/assets/images/distributionIndex/bankCard.png"
                alt=""
              />
            </div>
            <div class="allocation-title">
              <p>绑定银行卡账号</p>
            </div>
          </div>
          <div class="allocation-step">
            <img
              style="width: 6.67px; height: 11.67px"
              class="tableCir"
              src="~@/assets/images/distributionIndex/rightIcon.png"
              alt=""
            />
            <!-- <span></span> -->
          </div>
        </div>

        <!-- 绑定支付宝账号 -->
        <div
          class="allocation"
          @click="toasts()"
        >
          <div class="allocation-left">
            <div>
              <img
                class="tableCir"
                src="~@/assets/images/distributionIndex/alipay.png"
                alt=""
              />
            </div>
            <div class="allocation-title">
              <p>绑定支付宝账号</p>
            </div>
          </div>
          <div class="allocation-step">
            <img
              style="width: 6.67px; height: 11.67px"
              class="tableCir"
              src="~@/assets/images/distributionIndex/rightIcon.png"
              alt=""
            />
            <!-- <span></span> -->
          </div>
        </div>

        <div style="width: 100%;text-align: center;margin-top: 30px;">
          <van-button
            round
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            @click="outlogin()"
          >
            退出登录
          </van-button>
        </div>

      </div>
    </div>

    <!-- 修改昵称和设置单张分佣共用 -->
    <van-overlay
      :show="show"
      @click="show = false"
    >
      <div
        class="wrapper"
        @click.stop
      >
        <div class="title">
          <p v-if="shownum == 1">{{ name }}</p>
          <p v-if="shownum == 0">修改昵称</p>
          <p v-if="shownum == 1">单张卡片分佣额度</p>
        </div>
        <div class="inp">
          <van-field
            v-model="inpVal"
            v-if="shownum == 0"
            placeholder="请输入昵称"
            input-align="center"
          />
          <van-field
            v-model="inpVal2"
            type="number"
            v-if="shownum == 1"
            placeholder="请输入额度"
            input-align="center"
          />
        </div>
        <div class="btns">
          <div
            class="cacle"
            @click="cancle()"
          >取消</div>
          <div
            class="ok"
            @click="btnOk()"
          >确定</div>
        </div>
      </div>
    </van-overlay>
    <!-- 添加新用户的弹窗 -->
    <van-overlay
      :show="newUserShow"
      @click="newUserShow = false"
    >
      <div
        class="wrapper"
        @click.stop
      >
        <p style="margin-top: 26px; font-size: 16px">添加我的用户</p>
        <div class="inp1">
          <van-field
            type="digit"
            v-model="newuser.UserPhone"
            maxlength="11"
            placeholder="请输入手机号"
          />
        </div>
        <div class="inp2">
          <van-field
            v-model="newuser.UserNickname"
            placeholder="请给用户起个昵称"
          />
        </div>

        <div
          class="btns"
          style="margin-top: 37px"
        >
          <div
            class="cacle"
            @click="cancle()"
          >取消</div>
          <div
            class="ok"
            @click="addNewUser()"
          >确定</div>
        </div>
      </div>
    </van-overlay>
    <!-- 修改统一额度 -->
    <van-overlay
      :show="showChange"
      @click="showChange = false"
    >
      <div
        class="wrapper"
        @click.stop
      >
        <div class="title">
          <p>设置单张卡片分佣额度</p>
        </div>
        <div
          class="inp"
          style="border: none"
        >
          <van-stepper v-model="allocation" />
        </div>
        <div class="btns">
          <div
            class="cacle"
            @click="cancle()"
          >取消</div>
          <div
            class="ok"
            @click="setmoney()"
          >确定</div>
        </div>
      </div>
    </van-overlay>
    <!-- 选择时间 -->
    <van-popup
      v-model="showMonth"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-picker
        title="选择月份"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  queryUserList,
  FinanceList,
  saveUserRemark,
  settingMoney,
  getTimeData,
  addUser,
} from "@/untils/distubritionIndex.js";

import headers from "@/views/distributionIndex/components/header.vue";
import tabConten from "@/views/distributionIndex/components/tabTwo.vue";

export default {
  name: "",
  components: {
    headers,
    tabConten,
  },
  data () {
    return {
      showChange: false, //修改统一额度的弹窗
      newuser: {
        UserPhone: "",
        UserNickname: "",
      }, //新用户
      newUserShow: false, //添加新用户弹框
      showMonth: false, //选择月份
      shownum: 0, //编辑显示
      name: "", //名字
      inpVal: "", //输入框
      inpVal2: "", //输入框
      user_id: "",
      show: false, //显示弹出框
      loading: false, //列表加载loading  我的下级
      finished: false, //是否完成了下级列表
      loading2: false, //列表加载loading  收益列表
      finished2: false, //是否完成了 收益列表
      id: 0, //我的id
      mobile: "", //我的手机号码
      info: {}, //我的基本信息
      isShowPro: false, //是否显示进度条
      page: 0, //这是我的下级page
      userlist: [], //用户列表
      pageB: 0, //收益列表的page
      moneyList: [], //收益列表
      tabActive: 0, //tab索引'
      allocation: 1, //分佣
      moneyListInfo: {
        moneyList: [],
        allmoney: 0,
      }, //收益列表详情
      timeList: [], ///时间列表
      activeTime: {}, // 选择的月份
      activemonth: "", //当月份
      columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], //选择器月份列表
      flag: false, //第一次是否改变
      activeUserInfo: {}, //点击的用户数据
      timeNow: "", //现在时间
      iconlist: [
        {
          name: "添加用户",
          icon: require("../../assets/images/distributionIndex/icon5.png"),
          path: "none",
        },
        {
          name: "官方发货",
          icon: require("../../assets/images/distributionIndex/icon2.png"),
          path: "uploadOeder",
        },
        {
          name: "自提发货",
          icon: require("../../assets/images/distributionIndex/icon1.png"),
          path: "selfDispath",
        },
        {
          name: "销卡明细",
          icon: require("../../assets/images/distributionIndex/icon3.png"),
          path: "cancelCard",
        },
        {
          name: "代客下单",
          icon: require("../../assets/images/distributionIndex/icon6.png"),
          path: "InsteadOfOrder",
        },
      ], //  图标数组
      tabsList: [
        {
          id: 0,
          name: "我的下级",
          icon: require("../../assets/images/distributionIndex/down.png"),
        },
        {
          id: 1,
          name: "导出订单",
          icon: require("../../assets/images/distributionIndex/export.png"),
        },
        {
          id: 2,
          name: "收益记录",
          icon: require("../../assets/images/distributionIndex/table.png"),
        },
        {
          id: 3,
          name: "设置",
          icon: require("../../assets/images/distributionIndex/setting.png"),
        },
      ], //tab栏数组
      fulltimeL: "", // 选择时间开始
      fulltimeR: "", // 选择结束时间
    };
  },
  created () {

  },
  async mounted () {
    await this.getUrlParams();
    if ((window.localStorage.getItem('UserInfo')) == '' || (window.localStorage.getItem('UserInfo')) == null || (window.localStorage.getItem('UserInfo')) == "undefined" || JSON.parse(window.localStorage.getItem('UserInfo')).mobile != this.mobile) {
      this.outlogin()
      return
    }
    this.getinfo();
    this.$refs.tabtwo.getDownUser()
    this.getTimeList();
  },
  methods: {
    outlogin () {
      // 退出登录
      window.localStorage.removeItem("UserInfo")
      window.localStorage.removeItem("uPhone")
      window.sessionStorage.removeItem("UserInfo")
      this.$router.push({ path: `/mobileLogin` })

    },

    //前往销卡明细
    toasts () {
      this.$toast("功能开发中,敬请期待！");
    },

    go (url, item, name) {
      if (url == "none") {
        this.newUserShow = true;
        return;
      }
      if (url == "") {
        this.$toast("功能开发中...");
        return;
      }
      if (item) {
        // console.log(item);
        this.$router.push({
          path: `/${url}`,
          query: { mobile: item.mobile, type: 2, id: item.id, name: name },
        }); //类似get请求  数据会暴露在地址栏  (携带数据跳转)
      } else {
        this.$router.push({ path: `/${url}` }); //类似get请求  数据会暴露在地址栏  (携带数据跳转)
      }
    }, //改变统一额度
    changeFn () {
      let data = {
        mobile: this.info.mobile,
        money: this.allocation,
        pid: 0,
        type: 2,
      };

      settingMoney({ data }).then((res) => {
        if (res.data.code == 200) {
          this.$toast(res.data.msg);
          this.saveallocation = this.allocation;
          this.showChange = false;
        } else {
          this.$toast(res.data.msg);
          this.allocation = this.saveallocation;
        }
      });
    },

    //新增用户
    addNewUser () {
      let data = {
        pid: this.info.id,
        mobile: this.newuser.UserPhone,
        nickname: this.newuser.UserNickname.trim(),
      };
      addUser({ data }).then((res) => {
        if (res.data.code == 200) {
          this.$toast(res.data.msg);
          this.newUserShow = false;
          this.page = 0;
          this.userlist = [];
          this.getMyUserList();
        } else {
          this.$toast(res.data.msg);
        }
      });
    },

    onConfirm (value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
      this.showMonth = false;
      console.log(value, index);
      this.timeList.forEach((item) => {
        if (value == item.month) {
          this.activeTime = item;
          this.activemonth = item.month;
        }
      });
      this.pageB = 0;
      this.moneyListInfo.moneyList = [];
      this.getEarningsInfo();
      this.finished2 = false;
    },
    onChange (picker, value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel () {
      // Toast('取消');
      this.showMonth = false;
    },

    //选择月份
    changeMonth () {
      this.showMonth = true;
    },
    // 获取时间列表
    getTimeList () {
      var time = Date.parse(new Date());
      var t = new Date(time);
      let month = t.getMonth() + 1;
      this.activemonth = month;
      let year = t.getFullYear();
      let day = t.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      this.timeNow = year + "." + month + "." + day;

      this.fulltimeL = year + "-" + month + "-" + "01";
      this.fulltimeR = year + "-" + month + "-" + day;

      getTimeData().then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.timeList = res.data.data.data;
          this.timeList.forEach((element) => {
            if (element.month == this.activemonth) {
              this.activeTime = element;
            }
          });
        }
      });
    },
    //setmoney

    //设置统一额度
    setmoney () {
      console.log(this.allocation);
      this.changeFn();
    },

    //修改额度
    btnOk () {
      if (this.shownum == 0) {
        let data = {
          name: this.inpVal,
          user_id: this.user_id,
          pid: this.info.id,
        };
        saveUserRemark({ data }).then((res) => {
          // console.log(res);
          if (res.data.code == 200) {
            this.show = false;
            this.page = 0;
            this.userlist = [];
            this.getMyUserList();
          }
        });
      } else {
        let data = {
          mobile: this.info.mobile,
          money: this.inpVal2,
          pid: this.activeUserInfo.id,
        };
        settingMoney({ data }).then((res) => {
          if (res.data.code == 200) {
            this.show = false;
            this.page = 0;
            this.userlist = [];
            this.getMyUserList();
          } else {
            this.$toast(`${res.data.msg}`);
          }
        });
      }
    },
    //取消
    cancle () {
      this.show = false;
      this.newUserShow = false;
      this.showChange = false;
      this.inpVal = "";
      this.inpVal2 = 0;
      this.newuser.UserNickname = "";
      this.newuser.UserPhone = "";
      // setTimeout(()=>{
      //     this.shownum = 0
      // },500)
    },
    //编辑
    edit (num, item) {
      //0是修改昵称   1是
      if (num == 0) {
        this.shownum = num;
        this.user_id = item.id;
        this.inpVal = item.nickname;
        this.show = true;
      } else if (num == 1) {
        this.shownum = num;
        this.name = item.nickname;
        this.inpVal2 = item.money;
        this.show = true;
        this.activeUserInfo = item;
      } else if (num == 3) {
        this.showChange = true;
      }
    },
    onLoad2 () {
      setTimeout(() => {
        this.getEarningsInfo();
        this.loading2 = false;
      }, 1000);
    },
    onLoad () {
      setTimeout(() => {
        this.getMyUserList();

      }, 1000);
    },

    // 切换tab页
    tabActiveFn (num) {
      if (this.tabActive == num) {
        return;
      }
      this.tabActive = num;
      if (num == 0) {
        this.page = 0;
        this.userlist = [];
        this.finished = false;
        this.getMyUserList();
      } else if (num == 2) {
        this.pageB = 0;
        this.finished2 = false;
        this.moneyListInfo.moneyList = [];
        this.getEarningsInfo();
      }
    },

    //获取收益信息
    getEarningsInfo () {
      // console.log();
      let data = {
        user_id: this.info.id,
        page: ++this.pageB,
        start_time: this.activeTime.time[0],
        end_time: this.activeTime.time[1],
      };
      FinanceList({ data }).then((res) => {
        // console.log(res);
        if (res.data.data.data.data == "") {
          this.finished2 = true;
        }

        this.moneyListInfo.moneyList = [
          ...this.moneyListInfo.moneyList,
          ...res.data.data.data.data,
        ];
        // this.moneyListInfo.moneyList = res.data.data.data
        this.moneyListInfo.allmoney = res.data.data.allMoney;
        // console.log(this.moneyListInfo.allmoney);
      });
    },
    //获取地址栏参数
    getUrlParams () {
      //  console.log(this.$route.query.mobile);
      if (this.$route.query.mobile) {
        // console.log(1234);
        this.mobile = this.$route.query.mobile;
        window.localStorage.setItem('uPhone', this.$route.query.mobile)

      } else {
        let uPhone = window.localStorage.getItem('uPhone')
        let lPhone = JSON.parse(window.localStorage.getItem('UserInfo')).mobile
        if (uPhone == lPhone) {
          return false

        } else {
          this.outlogin()
          return false
        }


      }


    },
    //获取个人信息
    async getinfo () {
      let data = {
        mobile: this.mobile,
      };
      if (data.mobile.trim() == '') { return }
      await this.getUserInfo({ data }).then((res) => {
        this.info = res.data.data.data;
        window.sessionStorage.setItem("UserInfo", JSON.stringify(this.info));
        window.localStorage.setItem('UserInfo', JSON.stringify(this.info));
        this.id = this.info.id;
        this.allocation = this.info.money;
      });



      // this.info = JSON.parse(sessionStorage.getItem('UserInfo'))
      // this.id = this.info.id;
      // this.allocation = this.info.money;
    },
    //我的用户
    getMyUserList () {
      let data = {
        mobile: this.mobile,
        page: ++this.page,
      };
      if (this.finished) return;
      if (this.mobile == '') return;
      queryUserList({ data }).then((res) => {
        // console.log(res);
        if (res.data.code != 200) {
          this.finished = true;
        }
        this.loading = false;
        if (res.data.data.data.length == 0) {
          this.finished = true;
        }
        this.userlist = [...this.userlist, ...res.data.data.data];

      });

    }
  }
};
</script>
<style scoped lang="less">
.box {
  min-height: 100vh;
  background-color: #f8f9fa;

  /deep/ .van-button--round {
    width: 50%;
  }
  .wrapper {
    position: absolute;
    left: 46px;
    top: 239px;
    width: 282px;
    height: 278px;
    border-radius: 10px;
    background: linear-gradient(
      180deg,
      #d1edfa 0%,
      #d1edfa 0%,
      #ffffff 23%,
      #ffffff 23%
    );

    /deep/ .van-stepper {
      display: flex;
      justify-content: center;
    }

    .inp1 {
      margin: 27px auto 21px;
      width: 260px;

      /deep/ .van-cell {
        border-radius: 60px;
        background-color: #eff3f6;
      }
    }

    .inp2 {
      // border-radius: 60px;
      margin: 0 auto;
      width: 260px;

      /deep/ .van-cell {
        border-radius: 60px;
        background-color: #eff3f6;
      }
    }

    .title {
      margin-top: 40px;
      margin-bottom: 30px;
    }

    p {
      color: #3d3d3d;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      // margin-top: 40px;
      // margin-bottom: 30px;
    }

    .inp {
      margin: 0 auto;
      width: 177px;
      border-bottom: 1px solid #10a0fd;

      /deep/ .van-cell {
        background-color: #fff0 !important;
      }
    }

    .btns {
      margin-top: 56px;
      display: flex;
      justify-content: space-around;
      font-size: 14px;

      .cacle {
        text-align: center;
        line-height: 36px;
        color: #1e6fff;
        width: 106px;
        height: 36px;
        border-radius: 30px;
        background: #d5e4ff;
      }

      .cacle:active {
        background-color: #a8c1ee;
      }

      .ok {
        text-align: center;
        line-height: 36px;
        color: white;
        width: 106px;
        height: 36px;
        border-radius: 30px;
        background: #1e6fff;
      }

      .ok:active {
        background-color: #2c67d4;
      }
    }
  }

  .contentbox {
    margin-top: -50px;
    min-height: 400px;
    background-color: #f8f9fa;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 40px;
    padding-bottom: 40px;

    .rectangle {
      margin: 0 auto;
      margin-top: 20px;
      width: 345px;
      height: 80px;
      border-radius: 10px;
      background-color: #ffffff;
      padding: 12px 0;
      box-sizing: border-box;
      box-shadow: 0 1px 7px 0px rgb(209, 209, 209);
      display: flex;
      justify-content: space-evenly;

      .rectangle-item {
        display: flex;
        width: 60px;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        height: 49px;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .tabs {
      display: flex;
      // background-color: #117EFE;
      justify-content: space-around;
      align-items: center;
      width: 345px;
      height: 80px;
      margin: 0 auto;

      .tabs-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 60px;
        height: 49px;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .table-info {
      position: relative;
      margin: 0 auto;
      width: 350px;
      min-height: 100px;
      border-radius: 10px;
      background: #ffffff;
      padding-bottom: 10px;
      // padding-bottom: 30px;
      .upicon {
        position: absolute;
        width: 19px;
        height: 11.78px;
        top: -11.78px;
        left: 40px;
      }

      .upicon2 {
        position: absolute;
        width: 19px;
        height: 11.78px;
        top: -11.78px;
        left: 62%;
        transform: translateX(-50%);
      }

      .upicon3 {
        position: absolute;
        width: 19px;
        height: 11.78px;
        top: -11.78px;
        left: 88%;
        transform: translateX(-50%);
      }

      .allocation {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 15px;
        align-items: center;

        .allocation-left {
          display: flex;
          align-items: center;
        }

        .allocation-step {
          height: 100%;
          display: flex;
          align-items: center;

          img {
            margin-left: 8px;
          }
        }

        .tableCir {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        .allocation-title {
          p:nth-child(1) {
            font-size: 14px;
            color: #333333;
            margin-bottom: 5px;
          }

          p:nth-child(2) {
            font-size: 10px;
            color: #9999;
          }
        }
      }

      .table-info-header2 {
        width: 350px;
        height: 59px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #c2d8ff;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        align-items: center;
        color: #888888;

        .month {
          font-size: 12px;
          color: #333333;

          span {
            font-size: 30px;
            margin-left: 4px;
            // margin-right: 4px;
          }

          .yue {
            font-size: 12px;
            margin-right: 4px;
          }

          img {
            width: 12px;
            height: 8px;
          }
        }

        .totalMoney {
          font-size: 14px;
          color: #333333;
          font-weight: 500;
        }
      }

      .detail {
        height: 30px;
        width: 100%;
        line-height: 30px;
        padding-left: 15px;
        font-size: 14px;
        color: #333333;
        box-sizing: border-box;
      }

      .table-info-item2 {
        height: 50px;
        width: 100%;
        padding-left: 15px;
        display: flex;
        box-sizing: border-box;
        // justify-content: space-around;
        align-items: center;

        .avatar {
          width: 50px;
          display: flex;
          margin-left: 2px;
          justify-content: center;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }
        }

        .info-item2-content {
          width: 100%;
          display: flex;
          padding: 0 10px;
          box-sizing: border-box;
          text-align: center;
          justify-content: space-between;

          .nickname {
            color: #1e6fff;
            width: 50px;
          }

          .time {
            color: #cccccc;
            width: 80px;
          }

          .money {
            color: #ff4747;
            width: 70px;
          }
        }
      }

      .table-info-header {
        width: 350px;
        height: 36px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #c2d8ff;
        // padding-left: 50px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #888888;

        .nickname {
          text-align: center;
          width: 50px;
        }

        .money {
          width: 60px;
        }

        .creattime {
          width: 70px;
          text-align: center;
        }

        .count {
          text-align: center;
          width: 60px;
        }
      }

      .table-info-item {
        display: flex;
        // justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;

        .avatar {
          width: 50px;
          display: flex;
          margin-left: 2px;
          justify-content: center;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .table-info-content {
        width: 350px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #888888;

        .upload {
          color: #117efe;
        }

        .nickname {
          color: #117efe;
          text-align: left;
          width: 50px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .money {
          color: #117efe;
          text-align: left;
          width: 30px;
        }

        .creattime {
          color: #ccc;
          width: 70px;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .count {
          color: #117efe;
          text-align: center;
          width: 50px;
        }
      }
    }
  }
}
</style>