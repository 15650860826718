<template>
  <div>
    <div class="table-info">
      <img
        class="upicon2"
        src="~@/assets/images/distributionIndex/upicon.png"
        alt=""
      />
      <div class="table-info-header2">
        <div class="row1">
          <div class="month">
            <div @click="selectMonth(0)">
              <span class="yue">{{ fulltimeL }}</span>
              <img
                src="~@/assets/images/distributionIndex/downIcon.png"
                alt=""
              />
            </div>
            <span>至</span>
            <div @click="selectMonth(1)">
              <span class="yue">{{ fulltimeR }}</span>
              <img
                src="~@/assets/images/distributionIndex/downIcon.png"
                alt=""
              />
            </div>
          </div>
          <div style="margin-right: 20px;font-weight: 500;" @click="allselect()">全选</div>
        </div>
        <div class="row2">
          <div class="search">
            <div class="inp">
              <van-field v-model="value" placeholder="请输入用户名" />
              <div class="search-btn" @click="searchbtn()">搜索</div>
            </div>
          </div>
          <div class="download" @click="exportFun()">导出</div>
        </div>
      </div>
      <div class="checkbox">
        <div
          class="checkd"
          :class="{
            checkedActive:
              selsectList.indexOf(item.mobile) == -1 ? false : true,
          }"
          v-for="(item, index) in list"
          :key="index"
          @click="seletuser(item)"
        >
          {{ item.nickname }}
        </div>
      </div>

        <div class="downLoadCenter" @click="go('downLoadcenter')">下载中心</div>
    </div>

    <van-popup v-model="showMonth" position="bottom" :style="{ height: '60%' }">
      <div v-if="timeActive == 0">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirm2"
          @cancel="onCancel"
        >
        </van-datetime-picker>
      </div>
      <div v-else>
        <van-datetime-picker
          v-model="currentDate2"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirm3"
          @cancel="onCancel"
        >
        </van-datetime-picker>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  queryUserListAll,
  exportData,

} from "@/untils/distubritionIndex.js";

export default {
  props: {},
  data() {
    return {
      showMonth: false,
      value: "",
      list: [],
      selsectList: [],
      fulltimeL: "",
      fulltimeR: "",
      minDate: new Date(2017, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2022, 7, 1),
      currentDate2: new Date(2022, 7, 1),
      timeActive: 0,
      mobile: "", //我的手机号
    };
  },
  mounted() {
    this.getTime();
    this.mobile = this.$route.query.mobile;

    // this.getDownUser();
  },
  watch: {},
  methods: {
    go(url){
      this.$router.push({ path: `/${url}` });
    },
      //搜索用户
    searchbtn(){
        this.getDownUser()

    },
    // 导出
    exportFun() {
      let data = {
        mobile: this.selsectList.join(","),
        startTime: this.fulltimeL + " 00:00:00",
        endTime: this.fulltimeR + " 23:59:59",
        userMobile: this.mobile,
      };
      if(data.mobile == '') return this.$toast('未选择导出数据')
      // console.log(data);
      exportData({ data }).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          window.location = res.data.data.data;
        }else{
          this.$toast(res.data.msg)
        }
      });
    },

    onConfirm2() {
      let year = this.currentDate.getFullYear();
      let month = this.currentDate.getMonth() + 1;
      let day = this.currentDate.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      let fulltime = year + "-" + month + "-" + day;

      var date1 = new Date(fulltime).getTime();
      var date2 = new Date(this.fulltimeR).getTime();
      if (date1 > date2) {
        this.$toast("起始时间不能大于结束时间");
        return false;
      }
      this.fulltimeL = fulltime;
      this.showMonth = false;
    },
    onConfirm3() {
      let year = this.currentDate2.getFullYear();
      let month = this.currentDate2.getMonth() + 1;
      let day = this.currentDate2.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      let fulltime = year + "-" + month + "-" + day;
      var date1 = new Date(this.fulltimeL).getTime();
      var date2 = new Date(fulltime).getTime();
      if (date1 > date2) {
        this.$toast("结束时间不能小于起始时间");
        return false;
      }
      this.fulltimeR = fulltime;
      this.showMonth = false;
    },
    onCancel() {
      this.showMonth = false;
    },

    getTime() {
      var time = Date.parse(new Date());
      var t = new Date(time);
      let month = t.getMonth() + 1;
      this.activemonth = month;
      let year = t.getFullYear();
      let day = t.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      // this.timeNow = year +'.'+ month+'.' +day

      this.fulltimeL = year + "-" + month + "-" + "01";
      this.fulltimeR = year + "-" + month + "-" + day;
    },
    // 全选
    allselect() {
      if (this.selsectList.length == this.list.length) {
        this.selsectList = [];
        console.log(this.selsectList);
      } else {
        this.selsectList = [];
        this.list.forEach((item) => {
          this.selsectList.push(item.mobile);
        });
      }
    },
    // 选择导出对象
    seletuser(item) {
      // console.log(item);
      if (this.selsectList.indexOf(item.mobile) == -1) {
        // console.log("不存在");
        this.selsectList.push(item.mobile);
      } else {
        // console.log("存在,索引是：", this.selsectList.indexOf(item.mobile));
        this.selsectList.splice(this.selsectList.indexOf(item.mobile), 1);
      }
    },
    //获取下级列表
    getDownUser() {
      let data = {
        mobile: this.mobile,
        searchName:this.value
      };
      if(data.mobile.trim() =='' ||data.searchName.trim() =='') return
      queryUserListAll({ data }).then(({ data: res }) => {
        // console.log(res);
        this.list = res.data.data;
      });
    },

    selectMonth(num) {
      // this.$emit("selcetmonth", num);
      this.timeActive = num;
      this.showMonth = true;
    },
  },
};
</script>
<style lang="less" scoped>
.table-info {
  position: relative;
  margin: 0 auto;
  width: 350px;
  min-height:200px;
  border-radius: 10px;
  padding-bottom: 20px;
  background: #ffffff;

  .upicon2 {
    position: absolute;
    width: 19px;
    height: 11.78px;
    top: -11.78px;
    left: 38%;
    transform: translateX(-50%);
  }

  .table-info-header2 {
    width: 350px;
    height: 73px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #c2d8ff;
    box-sizing: border-box;
    // display: flex;
    // justify-content: space-between;
    padding: 0 10px;
    // align-items: center;
    color: #888888;

    .row2 {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      .download {
        width: 66px;
        height: 26px;
        background-color: #1e6fff;
        color: white;
        text-align: center;
        line-height: 26px;
        border-radius: 13px;
      }
      .search {
        width: 200px;
        height: 26px;
        .inp {
          width: 200px;
          height: 26px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          /deep/ .van-cell {
            padding: 0;
            border-radius: 20px;
            width: 161px;
          }
          /deep/ .van-field__value {
            padding-left: 10px;
          }

          .search-btn {
            font-weight: 500;
width: 30px;
            height: 17px;
            line-height: 17px;
            text-align: center;
            color: #333333;
          }
        }
      }
    }

    .row1 {
      width: 100%;
      height: 30px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      box-sizing: border-box;
      color: #333333;
      .month {
        font-size: 12px;
        color: #333333;
        display: flex;

        span {
          font-size: 16px;
          margin-left: 4px;
          // margin-right: 4px;
        }

        .yue {
          font-size: 12px;
          margin-right: 4px;
        }

        img {
          width: 12px;
          height: 8px;
        }
      }
    }
  }
  .downLoadCenter {
    // position: absolute;
    width: 141px;
    left: 50%;
    // transform: translateX(-50%);
    margin: 0 auto;
    // margin-bottom: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 60px;
    background: #1e6fff;
    text-align: center;
    color: white;
    bottom: 20px;
    box-shadow: 0 2px 1px 0px rgb(212, 209, 209);
  }
   .downLoadCenter:active{
    background-color: #094ec5;
   }
  .checkbox {
    width: 100%;
    padding: 17px 11px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    .checkd {
      min-width: 50px;
      max-width: 70px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      border-radius: 13px;
      // box-sizing: border-box;
      padding: 0px 8px;
      background-color: #f7f7f7;
      color: #999;
      margin-right: 10px;
      margin-bottom: 10px;
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*隐藏后添加省略号*/
      white-space: nowrap; /*强制不换行*/
    }

    .checkedActive {
      background-color: #e1ecff !important;
      color: #425e8d !important;
    }
  }
}
</style>